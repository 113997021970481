.grid {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 15px;
    row-gap: 10px;
    width: 100%;

    @media(min-width: 56.25em) {
        grid-template-columns: repeat(12, 1fr);
    }
}

@for $i from 0 to 13 {
    .cols-#{$i} {
        grid-column: span $i;
        max-width: 100%;
        padding: 5px;
    }
}