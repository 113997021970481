@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  src: local(""), url("roboto-v29-latin-100.414fc834.woff2") format("woff2"), url("roboto-v29-latin-100.bfded1a4.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  src: local(""), url("roboto-v29-latin-300.dc97820c.woff2") format("woff2"), url("roboto-v29-latin-300.5cfb2c9f.woff") format("woff");
}

html, body {
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  height: 100% !important;
}

html body, body body {
  margin: 0;
  padding: 8px;
}

footer {
  color: gray;
  width: 100%;
  height: 25px;
}

*, :before, :after {
  box-sizing: inherit;
}

.grid {
  grid-template-columns: 1fr;
  gap: 10px 15px;
  width: 100%;
  display: grid;
}

@media (min-width: 56.25em) {
  .grid {
    grid-template-columns: repeat(12, 1fr);
  }
}

.cols-0 {
  grid-column: span 0;
  max-width: 100%;
  padding: 5px;
}

.cols-1 {
  grid-column: span 1;
  max-width: 100%;
  padding: 5px;
}

.cols-2 {
  grid-column: span 2;
  max-width: 100%;
  padding: 5px;
}

.cols-3 {
  grid-column: span 3;
  max-width: 100%;
  padding: 5px;
}

.cols-4 {
  grid-column: span 4;
  max-width: 100%;
  padding: 5px;
}

.cols-5 {
  grid-column: span 5;
  max-width: 100%;
  padding: 5px;
}

.cols-6 {
  grid-column: span 6;
  max-width: 100%;
  padding: 5px;
}

.cols-7 {
  grid-column: span 7;
  max-width: 100%;
  padding: 5px;
}

.cols-8 {
  grid-column: span 8;
  max-width: 100%;
  padding: 5px;
}

.cols-9 {
  grid-column: span 9;
  max-width: 100%;
  padding: 5px;
}

.cols-10 {
  grid-column: span 10;
  max-width: 100%;
  padding: 5px;
}

.cols-11 {
  grid-column: span 11;
  max-width: 100%;
  padding: 5px;
}

.cols-12 {
  grid-column: span 12;
  max-width: 100%;
  padding: 5px;
}

.badge {
  margin: 4px;
  padding: 5px 10px;
  display: inline-block;
}

.badge-border-thin {
  border: 1px solid #7a7a7a;
}

.spinner {
  content: "";
  border: 2px solid #d3d3d3;
  border-top-color: #000;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  animation: 1s linear infinite spinner;
  display: block;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.repository {
  word-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  cursor: pointer;
  border: 2px solid #000;
  width: 100%;
  min-width: 100%;
  margin: 10px auto;
  padding: 15px 20px;
  display: inline-block;
}

.repository .repository-name {
  min-height: 19px;
  font-weight: bolder;
}

.repository .repository-description {
  min-height: 19px;
  max-height: 19px;
}

.repository .repository-license {
  min-height: 19px;
}

.repository .repository-license .license-icon {
  margin-right: 7px;
}

.repository * {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.repository-link {
  margin-left: 5px;
  margin-right: 5px;
  display: block;
}

.repository-btn {
  cursor: pointer;
  text-transform: uppercase;
  background: none;
  border: 2px solid #000;
  width: 100%;
  height: 50px;
  font-size: 13px;
  display: block;
}

.contributions-placeholder {
  margin-bottom: 15px;
}

.text-block {
  text-align: justify;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-light {
  font-size: 16px;
  font-weight: lighter;
}

.no-margin {
  margin: 0;
}

.fab {
  font-size: 35px;
}

.fill-height {
  height: 100%;
}

.fill-width {
  width: 100%;
}

a, a:hover, a:active {
  color: #000;
  border: none;
  outline: none;
}

.social {
  margin: 10px;
}

.hidden {
  display: none;
}

.hidden-link {
  text-decoration: none;
}

.noscript {
  margin-bottom: 5px;
}

.profile {
  text-align: center;
}

@media (min-width: 56.25em) {
  .profile {
    text-align: right;
  }
}

.profile-picture {
  text-align: center;
  border-radius: 50%;
  width: 128px;
  height: 128px;
}

@media (min-width: 56.25em) {
  .profile-picture {
    margin-right: 15px;
  }
}

.profile-text {
  text-align: center;
}

@media (min-width: 56.25em) {
  .profile-text {
    text-align: left;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.profile-text h1, .profile-text h2 {
  text-align: center;
}

@media (min-width: 56.25em) {
  .profile-text h1, .profile-text h2 {
    text-align: left;
  }
}

.index-1 {
  z-index: 1;
}

.index-2 {
  z-index: 2;
}

.index-3 {
  z-index: 3;
}

.index-4 {
  z-index: 4;
}

.index-5 {
  z-index: 5;
}

.index-6 {
  z-index: 6;
}

.index-7 {
  z-index: 7;
}

.index-8 {
  z-index: 8;
}

.index-9 {
  z-index: 9;
}

.index-10 {
  z-index: 10;
}

.index-11 {
  z-index: 11;
}

/*# sourceMappingURL=index.d1fce887.css.map */
