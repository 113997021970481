.repository {
    display: inline-block;
    width: 100%;
    padding: 15px 20px;
    margin: 10px auto;
    border: 2px solid black;
    min-width: 100%;
    word-wrap: break-word;
    hyphens: auto;
    cursor: pointer;

    .repository-name {
        min-height: 19px;
        font-weight: bolder;
    }

    .repository-description {
        max-height: 19px;
        min-height: 19px;
    }

    .repository-license {
        min-height: 19px;

        .license-icon {
            margin-right: 7px;
        }
    }

    * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.repository-link {
    display: block;
    margin-left: 5px;
    margin-right: 5px;
}

.repository-btn {
    display: block;
    height: 50px;
    width: 100%;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 13px;
    background: none;
    border: 2px solid black;
}

.contributions-placeholder {
    margin-bottom: 15px;
}