.text-block {
    text-align: justify;
    hyphens: auto;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-light {
    font-size: 16px;
    font-weight: lighter;
}

.no-margin {
    margin: 0 0;
}

.fab {
    font-size: 35px;
}

.fill-height {
    height: 100%;
}

.fill-width {
    width: 100%;
}

a,
a:hover,
a:active {
    color: black;
    border: none;
    outline: none;
}

.social {
    margin: 10px;
}

.hidden {
    display: none;
}

.hidden-link {
    text-decoration: none;
}

.noscript {
    margin-bottom: 5px;
}

.profile {
    text-align: center;

    @media(min-width: 56.25em) {
        text-align: right;
    }
}

.profile-picture {
    height: 128px;
    width: 128px;
    border-radius: 50%;
    text-align: center;

    @media(min-width: 56.25em) {
        margin-right: 15px;
    }
}

.profile-text {
    text-align: center;

    @media(min-width: 56.25em) {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        text-align: left;
    }

    h1, h2 {
        text-align: center;

        @media(min-width: 56.25em) {
            text-align: left;
        }
    }
}

@for $i from 1 to 12 {
    .index-#{$i} {
        z-index: $i;
    }
}