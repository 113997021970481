html, body {
    box-sizing: border-box;
    height: 100% !important;

    body {
        margin: 0 0;
        padding: 8px 8px;
    }
}

main {
    /*
    height: 100%;
    margin-bottom: -25px;
    */
}

footer {
    height: 25px;
    width: 100%;
    color: gray;
}

*, *:before, *:after {
    box-sizing: inherit;
}
