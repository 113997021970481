.spinner {
    display: block;
    content: "";
    height: 60px;
    width: 60px;
    margin: 0 auto;
    border: 2px solid lightgray;
    border-top-color: black;
    border-radius: 50%;
    animation: spinner 1s linear infinite;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}